<template>
  <section>
    <b-card
      no-body
      class="card-browser-states"
    >
      <b-card-header>
        <b-card-title class="ll-title"></b-card-title>
        <b-form-input
          v-model="tkid"
          placeholder="Enter TKID to search"
          type="search"
          class="ll-input"
        />
      </b-card-header>
      <b-card-body class="ll-cb">
        <b-table :items="items" :fields="fields"
          :borderless='true' :hover="true"
          :sticky-header="true"
          class="ll-tableHei"
          :style="styleVar"
        />
        <div class="d-flex justify-content-between flex-wrap ll-p">
          <div class="d-flex align-items-center mb-0 mt-1 mr-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>

  </section>
</template>

<script>
import {
  BTable, BCard, BCardBody, BCardHeader, BCardTitle, BFormInput,
  BPagination, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BPagination,
    BFormSelect,
  },
  props: {
    invoiceNo: String,
  },
  data() {
    return{
      condition: {
        page: '1',
        size: '10',
        orderBy: 'id',
        orderMole: 1,
        status: null,
        invoiceType: null,
        beginDate: null,
        endDate: null,
        refNo: null,
        invoiceNo: null,
      },
      items: [],
      fields: [
        { key: 'tkID', label: this.$t('TKID') },
        { key: 'status', label: this.$t('Billing status') },
        { key: 'feeType', label: this.$t('Fee type') },
        { key: 'amount', label: this.$t('Amount') },
        // { key: 'remark', label: 'Remark' },
      ],
      tkid: '',
      tableHei: document.documentElement.clientHeight*0.5,
      pageOptions: [10, 30, 50],
      page: null,
      size: 10,
      totalSize: null,
      totalPage: null,
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.tableHei + 'px'
      }
    },
  },
  watch: {
    invoiceNo(val) {
      this.getDetail(val)
    },
  },
  created() {},
  mounted() {},
  methods: {
    getDetail(ino) {
      this.condition.invoiceNo = ino
      this.$http.post('invoiceDetail/search', this.condition ).then(res => {
        const info = res.data.data
        this.items = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
      }).catch(error => console.log(error))
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getDetail(this.invoiceNo)
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getDetail(this.invoiceNo)
    },
  },
}
</script>

<style scoped>
.ll-title{
  width: 70%;
  margin: auto;
}
.ll-input{
  width: 30%;
}
.ll-tableHei{
  height: var(--height);
  max-height: unset;
}
.ll-p{
  align-items: center;
}
</style>
<style>
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>