<template>
  <section>
    <b-row>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ invoiceNo }}</span>
      </b-col>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ status }}</span>
      </b-col>
    </b-row>
    <b-row class="ll-hr">
      <b-col lg="3" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('General') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Customer') }}:</label>
          <span class="ll-span">{{customerName}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Invoice date') }}:</label>
          <span class="ll-span"> {{ invoiceDate }}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Account No') }}:</label>
          <span class="ll-span">{{account.accountNumber}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Due days') }}:</label>
          <span class="ll-span">{{account.netDays}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Invoice due day') }}:</label>
          <span class="ll-span">{{dueDate}}</span>
        </div>
      </b-col>
      <b-col lg="3" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('Invoice details') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Invoice Type') }}:</label>
          <span class="ll-span">{{invoiceType}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Total Amount') }}:</label>
          <span class="ll-span">{{totalAmount}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Qty') }}:</label>
          <span class="ll-span">{{ qty }}</span>
        </div>
      </b-col>
      <b-col lg="6" class="ll-Sedfloor">
        <b-row>
          <b-col lg="6">
            <p class="ll-pTit">{{ $t('Bill to') }}</p>
            <div class="ll-p1">
              <p>{{ account.billToAddress1 }}</p>
              <p>{{ account.billToCity }} {{account.billToProvince}} {{account.billToPostal}}</p>
              <p>{{ account.billToCountryCode }}</p>
              <p>{{ account.billToName }}</p>
            </div>
          </b-col>
          <b-col lg="6">
            <p class="ll-pTit">{{ $t('Remit to') }}</p>
            <div class="ll-p1">
              <p>{{ account.remitToAddress1 }}</p>
              <p>{{ account.remitToCity }} {{account.remitToProvince}} {{account.remitToPostal}}</p>
              <p>{{ account.remitToCountryCode }}</p>
              <p>{{ account.remitToName }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12" class="ll-padding">
        <b-card
          no-body
          class="card-browser-states ll-c ll-hei"
        >
          <b-card-header>
            <b-card-title>{{ $t('Fee summary') }}</b-card-title>
          </b-card-header>
          <b-card-body class="ll-cb">
            <b-table :items="feeSummaryList" :fields="fields"
              :borderless='true' :hover="true"
              :sticky-header="true"
              class="ll-tableHei"
              :style="styleVar"
            >
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BTable,
  BFormInput,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    invoiceNo: String,
    id: Number,
    heiVal: Number,
  },
  data() {
    return {
      invoiceDate: null,
      invoiceType: null,
      qty: null,
      totalAmount: null,
      account: {},
      feeSummaryList: null,
      fields: [
        { key: 'feeType', label: this.$t('Fee type') },
        { key: 'quantity', label: this.$t('Quantity') },
        { key: 'subTotal', label: this.$t('Subtotal') },
      ],
      customerName: null,
      status: null,
      dueDate: null,
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.tableHei + 'px'
      }
    },
  },
  watch: {
    id(val) {
      this.getDetail(val)
    },
    heiVal(val) {
      // console.log('val1', val)
      if (val < 490) {
        this.tableHei = val*0.2
      } else if (491 < val < 580) {
        this.tableHei = val*0.3
      } else {
        this.tableHei = val*0.4
      }
      // console.log('thei1',this.tableHei)
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getDetail(id) {
      this.$http.get('invoices/getDetail', { params: { id: id } }).then(res => {
        const info = res.data.data
        // console.log('ss', info)
        this.invoiceDate = info.createdAt
        this.invoiceType = info.invoiceType
        this.qty = info.quantity
        this.totalAmount = info.totalAmount
        this.account = info.data.account
        this.feeSummaryList = info.data.feeSummaryList
        this.customerName = info.customerName
        this.status = info.status
        this.dueDate = info.dueDate
      })
    },

  },
}
</script>
<style scoped>
.ll-spanBox{
  margin: 10px 0;
}
.ll-spanBox span{
  font-size: 18px;
  font-weight: bold;
}
.ll-box{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.ll-box .ll-lab{
  color: #6e6b7b;
}
.ll-pTit{
  /* color: #4B7902; */
  color: #ed6d01;
  font-size: 14px;
}
.ll-line{
  border-right: 1px solid #d0d2d6;
}
/* .ll-p{
  font-weight: 500;
  color: #5e5873;
} */
.ll-p p{
  margin-bottom: 0.2857rem;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
}
.ll-Sedfloor{
  margin-bottom: 10px;
}
.ll-trackStatus{
  width: 80%;
  border: 1px solid #eee;
  height: 40px;
  display: flex;
}
.ll-colorSpan{
  display: inline-block;
  width: 2%;
  height: 39px;
  background: #4B7902;
}
.ll-desSpan{
  line-height: 40px;
  padding-left: 20px;
}
.ll-wrapper{
  display: flex;
}
.ll-wrap{
  width: 100%;
}
.ll-timeline{
  height: 58px;
}
.ll-tl{
 margin-bottom: 15px;
}
.ll-pt{
  margin: 20px 0;
}
.icon-sussess{
  color: #28c76f;
}
.icon-fail{
  color: #ff9f43;
}
.ll-tableHei{
  /* height: 260px; */
  height: var(--height);
  max-height: unset;
}
.ll-padding{
  padding: 0;
}
.ll-cb{
  padding: 0;
}
.ll-c{
  margin-top: 10px;
}
.ll-spanBox .ll-sumt{
  font-size: 1rem;
  font-weight: unset;
  margin-left: 5px;
}
.ll-title{
  width: 70%;
  margin: auto;
}
.ll-input{
  width: 30%;
}
</style>
<style>
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{
  background-color: #fff;
}
</style>
